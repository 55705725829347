// import {Ionicons} from '@expo/vector-icons';
import {Entypo,Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
// import {MaterialCommunityIcons} from '@expo/vector-icons';
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';
import * as React from 'react';

import useColorScheme from '../hooks/useColorScheme';
import TabWelcomeScreen from '../screens/TabWelcomeScreen';
import TabCheckInScreen from '../screens/TabCheckInScreen';
import TabGuestGuideScreen from '../screens/TabGuestGuideScreen';
import TabVesselInfoScreen from '../screens/TabVesselInfoScreen';
import TabChatScreen from '../screens/TabChatScreen';
import TransfersChatScreen from '../screens/TransfersChatScreen';
import BookingChatScreen from '../screens/BookingChatScreen';
import ServiceChatScreen from '../screens/ServiceChatScreen';
import TabWeatherScreen from '../screens/TabWeatherScreen';
import TabSosScreen from '../screens/TabSosScreen';
import TabAboutScreen from "../screens/TabAboutScreen";

function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
    return <Ionicons size={40} style={{marginBottom: -3}} {...props} />;
}


export default function MaterialBottomTabNavigator() {
// export default class MaterialBottomTabNavigator {

    function componentDidMount() {
        alert("component mounted");
    }

    // render() {
    const colorScheme = useColorScheme();
    const Tab = createMaterialBottomTabNavigator();
    function ChatTabs() {
        return (
            <Tab.Navigator style={{height:20}}>
                <Tab.Screen name="Transfers" component={TransfersChatScreen}
                            options={{
                                title: 'Transfers',
                                // tabBarColor: '#2b65c3',
                                tabBarColor: '#2d4f70'
                            }}/>
                <Tab.Screen name="Booking" component={BookingChatScreen}
                            options={{
                                title: 'Booking',
                                // tabBarColor: '#2b65c3',
                                tabBarColor: '#2d4f70'
                            }}/>
                <Tab.Screen name="Services" component={ServiceChatScreen}
                            options={{
                                title: 'Services',
                                // tabBarColor: '#2b65c3',
                                tabBarColor: '#2d4f70'
                            }}
                />
            </Tab.Navigator>
        );
    }
    return (
        <Tab.Navigator
            shifting={true}>

            <Tab.Screen name="Welcome" component={TabWelcomeScreen}

                        options={{
                            title: 'Welcome',
                            // tabBarColor: '#2b65c3',
                            tabBarColor: '#143d66',
                            // tabBarIcon: ({color}) => <TabBarIcon name="home" size={25} color={color}/>,
                            tabBarIcon: ({color}) => <MaterialCommunityIcons name="home"
                                                                             size={25} color={color}/>,
                        }}
            />
            <Tab.Screen name="CheckIn" component={TabCheckInScreen}

                        options={{
                            // tabBarColor: '#1e9112',
                            tabBarColor: '#2d4f70',
                            tabBarIcon: ({color}) => <Ionicons name="checkmark-done" size={25}
                                                               color={color}/>,
                        }}/>
            <Tab.Screen name="GuestGuide" component={TabGuestGuideScreen}
                        options={{
                            // tabBarColor: '#8f153e',
                            tabBarColor: '#78828d',
                            tabBarIcon: ({color}) => <MaterialCommunityIcons name="television-guide"
                                                                             size={25} color={color}/>,
                        }}/>
            <Tab.Screen name="VesselInfo" component={TabVesselInfoScreen}
                        options={{
                            // tabBarColor: '#157d8f',
                            tabBarColor: '#999999',
                            tabBarIcon: ({color}) => <Ionicons name="boat" size={25} color={color}/>,
                        }}/>
            <Tab.Screen name="Chat" component={TabChatScreen}
            //<Tab.Screen name="Chat" component={ChatTabs}
                        options={{
                            // tabBarBadge: '0',
                            // tabBarColor: '#e06512',
                            tabBarColor: '#000000',
                            tabBarIcon: ({color}) => <Entypo name="message" size={25} color={color}/>,
                        }}/>
            <Tab.Screen name="Weather" component={TabWeatherScreen}
                        options={{
                            // tabBarColor: '#ffa91a',
                            tabBarColor: '#a68128',
                            tabBarIcon: ({color}) => <MaterialCommunityIcons name="weather-sunny" size={25}
                                                                             color={color}/>,
                        }}/>
            <Tab.Screen name="S.O.S." component={TabSosScreen}
                        options={{
                            tabBarColor: '#ef1a1a',
                            // tabBarColor: '#c7aa64',
                            // tabBarColor: '#b4923e',
                            tabBarIcon: ({color}) => <Ionicons name="help-buoy" size={25} color={color}/>,
                        }}/>

            <Tab.Screen name="About" component={TabAboutScreen}
                        options={{
                            // tabBarColor: '#ef1a1a',
                            tabBarColor: '#041132',
                            // tabBarColor: '#c7aa64',
                            // tabBarColor: '#b4923e',
                            tabBarIcon: ({color}) => <Ionicons name="information" size={25} color={color}/>,
                        }}/>
        </Tab.Navigator>
    );
    // }
}
