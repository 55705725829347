import React, {Component, useState} from 'react';
import {StyleSheet, View, Platform, ActivityIndicator} from 'react-native';
import {WebView} from "react-native-webview";
import ErrorNotImplemented from "../Error/ErrorNotImplemented";

// import PDFReader from 'rn-pdf-reader-js'; //https://openbase.com/js/rn-pdf-reader-js
// import PDFViewer from 'pdf-viewer-reactjs'

var showLoadingIndicator = true;
// var showLoadingIndicator = false;

export default function PdfView5({source}: { source: any }) {

    const [indicator, setIndicator] = useState(true);
    // const [indicator, setIndicator] = useState(false);

    // console.log("SOURCE:",source);
    console.log("---PDF VIEWER---");
    if (Platform.OS == 'android' || Platform.OS == 'web') {
        return (
            <View style={styles.container}>
                <ActivityIndicator animating={indicator} hidesWhenStopped={true} size="large" color="white"/>
                {/*<PDFReader*/}
                {/*    useGoogleReader={false}*/}
                {/*    onLoadEnd={() => {*/}
                {/*    setIndicator(false);*/}
                {/*}}*/}
                {/*           source={{uri:source}}*/}
                {/*           // source={{uri:"https://motoryachtscroatia.com/wp-content/uploads/Explorer-62-BF-brochure.pdf"}}*/}
                {/*           // source={{uri:"https:\\/\\/motoryachtscroatia.com\\/wp-content\\/uploads\\/Explorer-62-BF-brochure.pdf"}}*/}
                {/*           // source={{uri:"https://motoryachtscroatia.com//wp-content//uploads//Explorer-62-BF-brochure.pdf"}}*/}
                {/*/>*/}


                <WebView
                    onLayout={() => {
                        setIndicator(false);
                    }} LoadEnd={() => {
                    setIndicator(false);
                }}
                    style={styles.pdf}
                    originWhitelist={['*']}
                    source={{uri: "https://docs.google.com/gview?embedded=true&url=https://motoryachtscroatia.com//wp-content//uploads//Explorer-62-BF-brochure.pdf"}}
                    // source={{uri: "https://docs.google.com/gview?embedded=true&url=" + source}}
                />

                {/*<WebView*/}
                {/*    style={styles.pdf}*/}
                {/*    bounces={false}*/}
                {/*    scrollEnabled={false}*/}
                {/*    source={{ uri: 'http://www.africau.edu/images/default/sample.pdf' }} />*/}


                {/*<PDFViewer*/}
                {/*    document={{*/}
                {/*        url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',*/}
                {/*    }}*/}
                {/*/>*/}

            </View>
        )
    } else {
        return (
            <View style={styles.container}>
                {/*<ActivityIndicator animating={indicator} hidesWhenStopped={true} size="large" color="white"/>*/}
                <ErrorNotImplemented message={"Pdf Reader not implemented on web" +
                    "\nInspect .pdf manually:\n "+ source}/>
            </View>
        )
    }

}

function stopActivityIndicator() {
    alert("Loaded");
    showLoadingIndicator = false;
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#fff',
        // justifyContent: "center",
        // flexDirection: "row",
        justifyContent: "space-around",
    },
    pdf: {
        flex: 1,
    }
});
