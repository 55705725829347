import {StatusBar} from 'expo-status-bar';
import React,{useEffect} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import { BackHandler, Alert } from 'react-native';
import selectColorScheme from './hooks/useColorScheme';

import MaterialNavigation from './navigation';
import 'react-native-gesture-handler';
import useResolveBasicData from "./hooks/useResolveBasicData";
import ErrorMain from "./components/Error/ErrorMain";
import {
  useFonts,
  Yantramanav_400Regular,
  Yantramanav_100Thin,
  Yantramanav_500Medium,
  Yantramanav_300Light,
  Yantramanav_700Bold,
  Yantramanav_900Black
} from '@expo-google-fonts/yantramanav';
import useScreenOrientation from "./hooks/useScreenOrientation";
import getDeviceInfoAndNetworkStatus from "./hooks/getDeviceInfoAndNetworkStatus";
import { Platform } from 'react-native';

export default function App() {
  var reason = "";
  var proceed = true;

  if(Platform.OS === 'ios'){
    console.log("Screen orientation not implemented. yet..")
    // var isScreenOriented = useScreenOrientation();
    // if(!isScreenOriented){
    //   reason = "Screen Orientation Failed";
    //   proceed = false;
    // }
  }
  else if(Platform.OS === 'android'){
    var isScreenOriented = useScreenOrientation();
    if(!isScreenOriented){
      reason = "Screen Orientation Failed";
      proceed = false;
    }
  }
  else if(Platform.OS === 'web'){
    console.log("No screen orientation needed")
  }
  else{
    console.log("No screen orientation needed")
  }


  var colorScheme = selectColorScheme();
  if(!colorScheme){
    reason = "Failed To Set Color Scheme";
    proceed = false;
  }

  // if(useResolveBasicData().) {
    var deviceStatus = getDeviceInfoAndNetworkStatus();
    if (!deviceStatus) {
      reason = "Failed to determine Device Info and status";
      proceed = false;
    }
  // }

  var isDataOperationComplete = useResolveBasicData();
  if(!isDataOperationComplete){
    reason = "Failed to Fetch Data";
    proceed = false;
  }

  // Setup fonts
  let [fontsLoaded] = useFonts({
    Yantramanav_400Regular,
    Yantramanav_100Thin,
    Yantramanav_500Medium,
    Yantramanav_300Light,
    Yantramanav_700Bold,
    Yantramanav_900Black
  });

  if(!fontsLoaded){
    reason = "Failed To load fonts";
    proceed = false;
  }

  // // # location permissions.. Location permission is only asked when you go into the Weather screen
  // var locationPermission = getLocationUsagePermission();
  // if(!locationPermission){
  //   reason = "Failed To get location permission";
  //   proceed = false;
  // }
  // console.log("LOCATION PERMISSION: ", locationPermission)



  useEffect(() => {
    //  ONEMOGUĆAVANJE BACK BUTTONA
    const backAction = () => {
      // BackHandler.exitApp()
      return true;
    };
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    return () => backHandler.remove();
  }, []);

  // ako faila nešto od datoteki, onda se vraća view sa obavijesti
  if (!proceed) {
    return (
        <ErrorMain reason={reason}/>
    );
  } else {
    return (
        <SafeAreaProvider>
          <StatusBar  hidden={true} translucent={true} hideTransitionAnimation={'none'}  style="light" />
          {/*<MaterialNavigation data={isDataOperationComplete} colorScheme={colorScheme}/>*/}
          <MaterialNavigation />
        </SafeAreaProvider>
    );
  }
}
