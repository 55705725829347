import * as React from 'react';
import AsyncStorage from "@react-native-async-storage/async-storage";

export class BaseScreen extends React.Component {
    state = {}


   async  componentDidMount() {
         }

    async getValueFromAsyncStorageByKey(key: string) {

        const dataFromMemory = await AsyncStorage.getItem(key)
            .catch(
                reason => {
                    console.log("Reason for LocalStorage retrieval Failure: ", reason);
                    return null;
                }
            ).then(
                () => {
                    console.log("Data from Memory1: ", dataFromMemory);
                }
            )
            .finally(
                () => {
                    console.log("Data from Memory2: ", dataFromMemory);
                }
            );

        console.log("Data from Memory3: ", dataFromMemory);
        // return dataFromMemory != null ? JSON.parse(dataFromMemory) : null;
    }


}

