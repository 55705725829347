import * as React from "react";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {Image, StyleSheet, Text, View} from "react-native";
import {RFPercentage} from "react-native-responsive-fontsize";

export default function ErrorNotImplemented({message}: { message: any }) {
    return (
        <SafeAreaProvider>
            <View style={styles.errorContainer}>
                <Image
                    style={styles.logo}
                    source={require('../../assets/images/logo-white.png')}
                />
                <View style={styles.separator}/>
                <Text style={styles.errorTitleText}>Not Implemented!</Text>
                <View style={styles.separator}/>
                <Text style={styles.errorText}>{message} </Text>
            </View>
        </SafeAreaProvider>
    );
}
const styles = StyleSheet.create({
    errorContainer: {
        flex: 1,
        padding: 5,
        backgroundColor: 'darkgray',
        color: 'white',
        textAlign: 'center',
        marginTop: 0,
        textAlignVertical: "center",
        justifyContent: 'center',
    },
    errorTitleText: {
        color: "white",
        fontSize: RFPercentage(3.5),
        textAlign: 'center',
    },
    errorText: {
        textAlign: 'center',
        fontSize: RFPercentage(2),
        color: "white",
    },
    separator: {
        marginVertical: 20,
        height: 2,
        alignSelf: "center",
        width: '80%',
        // backgroundColor:"#ef1a1a"
        backgroundColor: "white"
    },
    logo: {
        alignSelf: "center",
        height: 78,
        width:250,
        // width: 66,
    },
});