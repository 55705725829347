import * as React from 'react';
import {StyleSheet} from 'react-native';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";//https://www.npmjs.com/package/react-native-responsive-fontsize

import {Text, View} from '../components/Themed';
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import SubmitSOS from "../components/Sos/SubmitSOS";


export default function TabAboutScreen() {
    return (
        <View style={styles.container}>
            {/*<Ionicons style={styles.icon} name="help-buoy" size={50}/>*/}

            <Text style={styles.title}>
                {/*<MaterialIcons style={styles.icon} name="copyright" size={20}/>*/}
                {/*2021. */}
                PrimeYachting d.o.o.
            </Text>

            <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
            <Text style={styles.text}>
                Created by
            </Text>
            {/*<View style={styles.separator2} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />*/}
            <Text style={styles.text2}>
                alt-tab
            </Text>
            <Text style={styles.text}>
                info@alt-tab.hr
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 15,
        fontFamily:"Yantramanav_100Thin",
        backgroundColor: '#041132',
        // backgroundColor: '#f64646',
        // backgroundColor: '#c7aa64',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    icon: {
        // color:"#ef1a1a"
        color:"white"
    },
    title: {
        // fontSize: 40,
        fontSize: RFPercentage(4),
        fontWeight: 'bold',
        // color:"#ef1a1a"
        color:"white",
        fontFamily:"Yantramanav_100Thin"
    },
    text: {
        // fontSize: 15,
        fontSize: RFPercentage(2),
        fontWeight: 'bold',
        color:"white",
        // alignSelf:"center",
        // alignItems:"center",
        // justifyContent: 'center',
        fontFamily:"Yantramanav_100Thin"
    },
    text2: {
        // fontSize: 15,
        fontSize: RFPercentage(3),
        fontWeight: 'bold',
        color:"white",
        // alignSelf:"center",
        // alignItems:"center",
        // justifyContent: 'center',
        fontFamily:"Yantramanav_100Thin"
    },
    separator: {
        marginVertical: 10,
        height: 1,
        width: '70%',
        // backgroundColor:"#ef1a1a"
        backgroundColor:"white"
    },
    separator2: {
        marginVertical: 10,
        height: 1,
        width: '30%',
        // backgroundColor:"#ef1a1a"
        backgroundColor:"white"
    },
});
