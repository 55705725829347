import * as React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {useEffect, useState} from 'react';

import EditScreenInfo from '../components/EditScreenInfo';
import {Text, View} from '../components/Themed';
import VideoPlayer5 from "../components/Video/VideoPlayer5";
import {BaseScreen} from "./BaseScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import {isResumable} from "react-native-fs";

// const testVideo = 'https://motoryachtscroatia.com/wp-content/uploads/Prime-Yachting-promo-video-final-without-text.mp4';
// const testVideo = 'http://techslides.com/demos/sample-videos/small.mp4';
async function getVideo() {
    // var testVideo = await getValueFromAsyncStorageByKey('boat_video3');
    var video = AsyncStorage.getItem('video_check_in_url');
    console.log("video:", video);
    return video;
}

// const testVideo = getVideo();


// export default async function TabCheckInScreen() {
export default class TabCheckInScreen extends React.Component {
    state = {
        isReady: false,
        isFocused: false,
        source: 'none'
    }

    async componentDidMount() {
        var video = await AsyncStorage.getItem('video_check_in_url');
        this.setState({isReady: true, isFocused: true, source: video});
    }

    // const [video, setVideo] = useState('');
    // const [loaded, setLoaded] = useState(false);
    // const isFocused = useIsFocused();
    render() {
        if (!this.state.isReady) {
            return (
                <View style={styles.activityIndicator}>
                    <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady}
                                       hidesWhenStopped={true} size="large" color="white"/>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <VideoPlayer5 videoUrl={this.state.source}/>
                </View>
            );
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#78828d'
    },
    activityIndicator: {
        flex: 1,
        // alignSelf: 'center',
        color: "white",
        fontWeight: 'bold',
        // backgroundColor: '#fac97a',
        backgroundColor: '#78828d',
    },
    activityIndicatorContents: {
        color: "white",
        fontWeight: 'bold',
        flex: 1,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
