import * as React from 'react';
import  { useRef, useState, useEffect } from 'react';
import {AppState }  from 'react-native';
import {View, StyleSheet, Button, Text} from 'react-native';
import {Video, AVPlaybackStatus} from 'expo-av';
import {useIsFocused} from '@react-navigation/native';

// export default function VideoPlayer5({video,videoUrl, focused}: {video:any, videoUrl: string, focused:boolean })


// export default function VideoPlayer5({videoUrl}: {videoUrl: string }) {
export default function VideoPlayer5({videoUrl}: {videoUrl: any}) {
    const video = React.useRef(null);
    const isFocused = useIsFocused();
    const [status, setStatus] = React.useState({});
    const jachtingVideo = 'https://www.youtube.com/watch?v=baLoDmobCZE';
    // const videoTest = ;
    if (video.current != null) {
        if (!isFocused) {
            if(status.isPlaying){
                video.current.pauseAsync();
            }
        }
    }

    return (

        <View style={styles.container}>

            <Video
                ref={video}
                style={styles.video}
                source={{uri: videoUrl}}
                // source={require(videoUrl)}
                // function to be called when the video begins to be loaded into memory. Called without any arguments.
                onLoadStart={() => {
                }}
                // A function to be called once the video has been loaded. The data is streamed so all of it may not have been fetched yet, just enough to render the first frame.
                onLoad={() => {

                }}

                //The desired position of playback in milliseconds. See the AV documentation for more information.
                positionMillis={0}

                useNativeControls
                resizeMode="cover"
                shouldPlay={true}
                // isPlaying={isFocused}
                // shouldPlay={isFocused}
                volume={0.5}
                isMuted={false}

                isLooping={false}
                // onPlaybackStatusUpdate={status => setStatus(() => status)}
                onPlaybackStatusUpdate={status =>{
                    setStatus(() => status);
                    // console.log(status);
                }}


            />
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#000000',
        alignSelf: 'stretch',

        // paddingBottom:50,
        // flexWrap: 'wrap',
        paddingTop: 1,
        alignItems: 'center',
        resizeMode: 'cover',
        justifyContent: 'center',
        textAlign: 'center',

    },
    video: {
        // alignSelf: 'center',
        // width: 320,
        // height: 200,
        flex: 1,

        //
        // alignSelf: 'stretch',

        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0

        // width: '100%',
        // height: '99%',
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
