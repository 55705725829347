import * as React from 'react';
import {StyleSheet} from 'react-native';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";//https://www.npmjs.com/package/react-native-responsive-fontsize



import {Text, View} from '../components/Themed';
import {Ionicons} from "@expo/vector-icons";
import SubmitSOS from "../components/Sos/SubmitSOS";


export default function TabSosScreen() {
    return (
        <View style={styles.container}>
            <Ionicons style={styles.icon} name="help-buoy" size={50}/>
            {/*<View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />*/}
            <Text style={styles.title}>S.O.S</Text>
            <Text style={styles.text}>
                Please use this option only in emergencies. Thank You.
            </Text>
            <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
            <Text style={styles.text}>
                Once You submit an S.O.S. message through this screen, we will notify all
                civil services about your emergency.
                If this is NOT an S.O.S. level incident, please use the Chat option instead.
            </Text>
            <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)"/>
            <SubmitSOS sos_signal_sent={false}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 15,
        fontFamily:"Yantramanav_100Thin",
        // backgroundColor: '#f6cfcf',
        backgroundColor: '#f64646',
        // backgroundColor: '#c7aa64',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    icon: {
        // color:"#ef1a1a"
        color:"white"
    },
    title: {
        // fontSize: 40,
        fontSize: RFPercentage(4),
        fontWeight: 'bold',
        // color:"#ef1a1a"
        color:"white",
        fontFamily:"Yantramanav_100Thin"
    },
    text: {
        // fontSize: 15,
        fontSize: RFPercentage(2),
        fontWeight: 'bold',
        color:"white",
        // alignSelf:"center",
        // alignItems:"center",
        // justifyContent: 'center',
        fontFamily:"Yantramanav_100Thin"
    },
    separator: {
        marginVertical: 10,
        height: 1,
        width: '80%',
        // backgroundColor:"#ef1a1a"
        backgroundColor:"white"
    },
});
