import * as React from 'react';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
//GREŠKA SA AXIOSIM I ENCODINGOM  RJEŠENJE
// import {decode, encode} from 'base-64'
var {decode, encode}  = require('base-64');
if (!global.btoa) {
    global.btoa = encode
}
if (!global.atob) {
    global.atob = decode
}

import * as FileSystem from 'expo-file-system';
import {
    getBoatGuestGuideUrl,
    getBoatSpecsUrl,
    getVideoCheckInUrl,
    fetchBoatDataUrl,
    checkDeviceRegisteredUrl, registerDeviceUrl,
    mainApiUsername,
    mainApiPassword
} from "../apiConfig";


export default async function useResolveBasicData() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    // Load any resources or data that we need prior to rendering the app
    await React.useEffect(() => {

        async function executeMainJsonDataRequest() {
            console.log("executeMainJsonDataRequest()");
            var res = await axios.get(fetchBoatDataUrl, {
                auth: {
                    username: mainApiUsername,
                    password: mainApiPassword
                },
                headers:{
                    "Cache-Control":'no-cache',
                    "Pragma":'no-cache',
                    "Expires":'0',
                }
            }).catch((e) => {
                console.log("DATA FETCH REJECTED BY SERVER: ", e);
            });

            if (res == undefined) {
                console.log("Data From server Failed: Result undefined!");
                return null;
            } else {
                console.log("Data received from server: OK!: ", res.data);
                var response = await JSON.stringify(res.data);
                return response;
            }
        }

        async function saveDataToLocalStorage(key: string, data: any) {
            try {
                console.log("Data saved to AsyncStorage");
                await AsyncStorage.setItem(key, data);
                return true;
            } catch (e) {
                console.log("Data failed to save to AsyncStorage");
                return false;
            }

        }

        async function downloadVideoCheckIn() {
            const device_id = await AsyncStorage.getItem("device_id");
            const res = await axios.post(getVideoCheckInUrl + device_id , {},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {
                        username: mainApiUsername,
                        password: mainApiPassword
                    }
                }).then(result => {
                    // console.log("result: ", result);
                    if (result.data.data.success != undefined) {
                        // console.log("result.data.data: ", result.data.data);
                        saveDataToLocalStorage('video_check_in_url',result.data.data.payload);
                        // console.log('video_check_in_url: ',result.data.data.payload)
                        return result.data.data.success;
                    }
                    // console.log("result.data.data: ", result.data.data);
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;

            });
            console.log("video_check_in_url Downloaded: ", res);
            return res;
        }
        async function downloadBoatSpecs() {
            const device_id = await AsyncStorage.getItem("device_id");
            const res = await axios.post(getBoatSpecsUrl + device_id , {},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {
                        username: mainApiUsername,
                        password: mainApiPassword
                    }
                }).then(result => {
                    // console.log("result: ", result);
                    if (result.data.data.success != undefined) {
                        // console.log("result.data.data: ", result.data.data);
                         saveDataToLocalStorage('boat_specs_url',result.data.data.payload);
                        // console.log('boat_specs_url: ',result.data.data.payload)
                        return result.data.data.success;
                    }
                    // console.log("result.data.data: ", result.data.data);
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;

            });
            console.log("BoatSpecs Downloaded: ", res);
            return res;
        }

        async function downloadGuestGuide() {

            const device_id = await AsyncStorage.getItem("device_id");
            console.log("getBoatGuestGuideUrl + device_id: ", getBoatGuestGuideUrl + device_id);
            const res = await axios.post(getBoatGuestGuideUrl + device_id , {},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {
                        username: mainApiUsername,
                        password: mainApiPassword
                    }
                }).then(result => {
                    console.log("result: ", result);
                    if (result.data.data.success != undefined) {
                        // console.log("result.data.data: ", result.data.data);
                        // console.log("result.data.data.payload: ",result.data.data.payload);
                        saveDataToLocalStorage('guest_guide_url',result.data.data.payload);
                        // console.log('guest_guide_url: ',result.data.data.payload)
                        return result.data.data.success;
                    }
                    // console.log("result.data.data: ", result.data.data);
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;

            });
            console.log("Guest Guide Downloaded: ", res);
            return res;
        }

        async function downloadWelcomeData() {
            const device_id = await AsyncStorage.getItem("device_id");
            const res = await axios.post(fetchBoatDataUrl+device_id, {"deviceUniqueId": device_id},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {username: mainApiUsername,
                        password: mainApiPassword}
                }).then(result => {
                    if (result.data.data.payload != undefined) {
                        // console.log("result.data.data: ",result.data.data);
                        saveDataToLocalStorage('greeting_message_title',result.data.data.payload.greeting_message_title);
                        saveDataToLocalStorage('greeting_message_content',result.data.data.payload.greeting_message_content);
                        saveDataToLocalStorage('welcome_msg_background_img',result.data.data.payload.welcome_msg_background_img);
                        return result.data.data.success;
                    }
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;
            });
            console.log("Device was Registered: ", res);
            return res;


        }

        async function checkIfDeviceRegistered() {
            const device_id = await AsyncStorage.getItem("device_id");
            const res = await axios.post(checkDeviceRegisteredUrl+device_id, {"deviceUniqueId": device_id},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {username: mainApiUsername,
                        password: mainApiPassword}
                }).then(result => {
                    if (result.data.data.registered != undefined) {
                        // console.log("result.data.data: ",result.data.data);
                        return result.data.data.registered;
                    }
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;
            });
            console.log("Device was Registered: ", res);
            return res;
        }

        async function registerDevice() {
            const device_id = await AsyncStorage.getItem("device_id");
            const device_name = await AsyncStorage.getItem("device_name");
            // const res = await axios.post(registerDeviceUrl, {"deviceUniqueId": device_id, "deviceName":device_name},
            const res = await axios.post(registerDeviceUrl+device_id+"/"+device_name,{},
                {
                    headers: {'Content-Type': 'application/json'},
                    auth: {username: mainApiUsername,
                        password: mainApiPassword}
                }).then(result => {
                    // console.log("result: ", result);
                    if (result.data.data.success != undefined) {
                        // console.log("result.data.data: ", result.data.data);
                        return result.data.data.success;
                    }
                    // console.log("result.data.data: ", result.data.data);
                    return false;
                }
            ).catch(e => {
                console.log("error: ", e);
                console.log("error: ", e.error);
                return false;

            });
            console.log("Device Registration complete: ", res);
            return res;
        }

        async function loadDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync();
                if(await checkIfDeviceRegistered() == false){
                    if(await registerDevice()){
                    }
                }
                else{
                    if(downloadWelcomeData()){}
                    if(downloadGuestGuide()){}
                    if(downloadBoatSpecs()){}
                    if(downloadVideoCheckIn()){}
                }

                // await FileSystem.getContentUriAsync(uri).then(cUri => {
                //     console.log("cUri:",cUri);
                //     AsyncStorage.setItem('boat_video3', cUri);
                // });


            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e);
            } finally {
                setLoadingComplete(true);
                SplashScreen.hideAsync();
            }
        }


        loadDataAsync();
    }, []);

    // return isLoadingComplete;
    return false;
}
