import * as React from 'react';
import {mainApiPassword, mainApiUsername, weatherInfoUrl} from "../apiConfig";
import {ActivityIndicator, Alert, StyleSheet} from "react-native";
import axios from "axios";
import {View} from "../components/Themed";
import {WebView} from "react-native-webview";
import * as Location from "expo-location";


// export default function TabWeatherScreen() {
export default class TabWeatherScreen extends React.Component {
    state = {
        isReady: false,
        lon: 0,
        lat: 0,
        htmlContents: ''
    }

    constructor(props: any) {
        super(props);
        this.findCoordinates();
    }

    async componentDidMount() {


        // try {
        //     await navigator.geolocation.getCurrentPosition(
        //         // position => async()=>{
        //         position => {
        //             const lon = position.coords.longitude;
        //             const lat = position.coords.latitude;
        //             this.setState({isReady: true, lon: lon, lat: lat});
        //             console.log({lon, lat});
        //             console.log("url weather: ", weatherInfoUrl + this.state.lon + '/' + this.state.lat + '/');
        //             // await console.log('https://'+mainApiUsername+':'+mainApiPassword+'@'+'py.alt-tab.hr/'+this.state.lon+'/'+this.state.lat+'/');
        //
        //         },
        //         error => {
        //             Alert.alert(error.message);
        //             return false
        //         },
        //         {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000}
        //     );
        // }catch (e){
        //     console.log("CANT FD COORDINATES");
        // }


        // const weather;
        await this.findCoordinates();
        const res = await axios.get(
            weatherInfoUrl + this.state.lon + '/' + this.state.lat,
            {
                auth: {
                    username: mainApiUsername,
                    password: mainApiPassword
                }
            });
        const htmlContents = res.data;
        const isReady = true;
        this.setState({isReady, htmlContents});
        // console.log("RES",htmlContents);
    }

    public findCoordinates = async () => {
        // await navigator.geolocation.getCurrentPosition(
        //     position => {
        //         const lon = position.coords.longitude;
        //         const lat = position.coords.latitude;
        //         const isReady = true;
        //         this.setState({ isReady:isReady,lon:lon, lat:lat});
        //         console.log({lon, lat});
        //     },
        //     error => Alert.alert(error.message),
        //     {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000}
        // );
        console.log("TRY TO GET LOCATION");
        let {status} = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            // setErrorMsg("Permission to access location was denied");
            return;
        }

        let location = await Location.getCurrentPositionAsync({});
        // console.log("Location: ", location.coords.latitude)
        // console.log("Location: ", location.coords.latitude)
        // console.log("Location: ", location.coords.longitude)
        const lon = location.coords.longitude;
        const lat = location.coords.latitude;
        this.setState({isReady: true, lon: lon, lat: lat});
        console.log({lon, lat});
        // console.log("url weather: ", weatherInfoUrl + this.state.lon + '/' + this.state.lat + '/');
        console.log("url weather: ", weatherInfoUrl + this.state.lon + '/' + this.state.lat + '/');

    };

    render() {
        if (!this.state.isReady) {
            return (
                <View style={styles.activityIndicator}>
                    <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady}
                                       hidesWhenStopped={true} size="large" color="orange"/>
                </View>
            )
        } else {
            return (
                <View
                    style={{
                        flex: 1
                    }}>
                    <WebView style={styles.webview_default}
                             originWhitelist={['http://*', 'https://*']}
                             javaScriptEnabled={true}
                             domStorageEnabled={true}
                             startInLoadingState={false}
                             scalesPageToFit={false}
                             onNavigationStateChange={(event) => {
                                 event.canGoForward = false;
                                 // this.ref.stopLoading();
                                 // console.log(event.goBack());
                                 // WebView.current.goBack();
                                 // this.webview.current.goBack()
                             }}
                             source={{
                                 uri: weatherInfoUrl + this.state.lon + '/' + this.state.lat + '/'
                             }}
                    />
                </View>
            );

        }

    }

}

const styles = StyleSheet.create({
    webview_default: {
        marginTop: 0,
        // height: 200,
        // width: 320,
        flex: 1
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fac97a',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    containerMain: {
        flex: 1,
        backgroundColor: '#fac97a',
        textAlign: "center",
        textAlignVertical: "center",
        justifyContent: 'center',
        // alignItems: 'center'
        // position: 'absolute', top: 0, bottom: 0, left: 0, right: 0
    },
    activityIndicator: {
        flex: 1,
        // alignSelf: 'center',
        color: "white",
        fontWeight: 'bold',
        backgroundColor: '#fac97a',
    },
    activityIndicatorContents: {
        color: "white",
        fontWeight: 'bold',
        flex: 1,
    }
});