import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import * as ScreenOrientation from "expo-screen-orientation";

// change screen orientation prior to rendering the app
// after completion hide splash screen
export default function useScreenOrientation() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);
    React.useEffect(() => {
        async function changeScreenOrientation() {
            try {
                SplashScreen.preventAutoHideAsync();
                ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
            } catch (e) {
                console.warn(e);
            } finally {
                setLoadingComplete(true);
                SplashScreen.hideAsync();
            }
        }
        changeScreenOrientation();
    }, []);
    return isLoadingComplete;
}
