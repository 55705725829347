import React, { Component, useState } from 'react';

import { StyleSheet, View, Platform, ActivityIndicator, ImageBackground, Text } from 'react-native';
import {RFPercentage, RFValue} from "react-native-responsive-fontsize";
import FloatingButton from "../Sos/FloatingButton";


export default function WelcomeAndImage({greeting_message_title, greeting_message_content, welcomeMsgBackgroundImgUrl }: { greeting_message_title: string, greeting_message_content: string, welcomeMsgBackgroundImgUrl: string }) {
    return (
        <ImageBackground source={{uri: welcomeMsgBackgroundImgUrl}} style={styles.imageBackground}>
            {/*<FloatingButton sos_signal_sent=(/>/{true}></FloatingButton>*/}
            {/*<FadeInWelcomeTitle style={{flex: 1}}>*/}
            {/*<FadeInWelcomeTitle>*/}
            <Text style={styles.welcomeTitle}>
                {greeting_message_title}

            </Text>
            <View style={styles.separator}/>
            {/*</FadeInWelcomeTitle>*/}

            {/*<FadeInWelcomeContent style={{flex: 1}}>*/}
            <Text style={styles.welcomeContent}>
                {greeting_message_content}
            </Text>
            {/*</FadeInWelcomeContent>*/}
        </ImageBackground>

    );

}


const styles = StyleSheet.create({
    imageBackground: {
        // flex: 1,
        // flexDirection: "column",
        // alignItems: 'center',
        // resizeMode: 'cover',
        // justifyContent: 'center',
        // color: '#fff',
        padding: 20,
        // textAlign: 'center',

        flex: 1,
        backgroundColor: '#3272db',
        textAlign: "center",
        textAlignVertical: "center",
        justifyContent: 'center',


    },
    welcomeTitle: {
        // fontSize: 40,
        // fontSize: RFValue(24, 580),
        fontSize: RFPercentage(4),
        fontWeight: 'bold',
        color: "white",
        fontFamily:"Yantramanav_300Light"

        // flex: 1,
    },
    welcomeContent: {
        // fontSize: 20,
        fontSize: RFPercentage(2),
        color: "white",
        fontWeight: 'bold',
        fontFamily:"Yantramanav_100Thin"
        // flex: 1,
    },
    separator: {
        marginVertical: 20,
        height: 1,
        alignSelf:"center",
        width: '100%',
        // backgroundColor:"#ef1a1a"
        backgroundColor: "white"
    }
});

