import React from 'react';
import {StyleSheet, Button, TouchableOpacity, Alert,} from 'react-native';
import {Text, View} from '../Themed';
import axios from "axios";

import {
    sosInsertUrl,
    mainApiUsername,
    mainApiPassword
} from "../../apiConfig";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function SubmitSOS({sos_signal_sent}: { sos_signal_sent: boolean }) {
    return (
        <View>
            <Button
                onPress={() => promptUserConfirmation()}
                // onPress={() => handleSosSubmition()}
                //title="Submit S.O.S. Signal" color={"#c7aa64"}
                title="Submit S.O.S. Signal" color={"#ef1a1a"}
                // style={styles.sosBtn}/>
            />
        </View>
    );
}

async function promptUserConfirmation() {
    // alert('S.O.S. Signal Sent! Hold tight, help is on the way.');

    Alert.alert(
        "Submit S.O.S. Signal?",
        "Are you sure?",
        [
            {
                text: "NO",
                onPress: () => console.log("Cancel Pressed"),
                style: "cancel"
            },
            {
                text: "YES", onPress: () => {
                    console.log("OK Pressed");
                    handleSosSubmition();
                }
            }
        ]);
}

async function handleSosSubmition() {
    // send mail to headquarters..
    // Šaljemo poruku na sos dio api aplikacije, te spremammo sos obavijest
    // u bazu podataka na serveru.
    // svi SOS pozivi vidljivi su u backend aplikaciji.
    // Prima post zahtjev ali u GET nizu je boatId preko kojeg se aktivira
    const device_id = await AsyncStorage.getItem("device_id");
    const res = await axios.post(sosInsertUrl + device_id, {"device_identifier": device_id},
        {
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            auth: {
                username: mainApiUsername,
                password: mainApiPassword
            }
        }).then(result => {
            console.log("result: ", result.data.data);
            if (result.data.data.success != undefined) {
                return result.data.data.success;
            }
            return false;
        }
    ).catch(e => {
            console.log("error: ", e);
            console.log("error: ", e.error);
            return false;
        }
    );
    console.log(res);
    if (res == false) {
        await Alert.alert(
            "S.O.S. Signal NOT Sent! ",
            "Server is not responding!"
        );
        // alert("S.O.S. Signal NOT Sent! ");
    } else {
        await Alert.alert(
            "S.O.S. Signal Sent! ",
            "Hold tight, help is on the way."
        );
        // alert("S.O.S. Signal Sent! ");
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        color: '#fff',
    },
    sosBtn: {
        backgroundColor: '#ee3333',
        // backgroundColor: '#c1a254',
        fontFamily:"Yantramanav_100Thin"
    },
});
