import * as React from 'react';
import {StyleSheet, View, ActivityIndicator} from 'react-native';
import {BaseScreen} from "./BaseScreen";
import Welcome from "../components/Welcome/Welcome";
import WelcomeAndImage from "../components/Welcome/WelcomeAndImage";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import FloatingButton from "../components/Sos/FloatingButton";

// export default function TabWelcomeScreen() {
export default class TabWelcomeScreen extends BaseScreen {
    state = {
        isReady: false,
        showImg:false,
        // isReady: false,
        // boatId: 0,
        // boatName: 'Bad internet Connection',
        // boatDescription: 'Bad internet Connection',
        greeting_message_title: 'Bad internet Connection',
        greeting_message_content: 'Bad internet Connection',
        welcome_msg_background_img: '',
        // welcomeMsgBackgroundImgUrl: '',
        // video_check_in_url:''
    }
    async componentDidMount() {
        var greeting_message_title = await AsyncStorage.getItem('greeting_message_title');
        var greeting_message_content = await AsyncStorage.getItem('greeting_message_content');
        var welcome_msg_background_img = await AsyncStorage.getItem('welcome_msg_background_img');
        var showImg = false;
        // console.log(this.state.welcome_msg_background_img);
        if (this.state.welcome_msg_background_img.length == 0) {
             showImg = true;
        }
        this.setState({
            isReady:true,
            showImg:showImg,
            greeting_message_title:greeting_message_title,
            greeting_message_content:greeting_message_content,
            welcome_msg_background_img:welcome_msg_background_img
        });
    }
    render() {
        // return (<View></View>);
        if (!this.state.isReady) {
            return (
                <View style={styles.containerMain}>
                    <View style={styles.activityIndicator}>
                        <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady}
                                           hidesWhenStopped={true} size="large" color="white"/>
                    </View>
                </View>
            )
        } else {
            if (!this.state.showImg) {
                return (
                    <View style={styles.containerMain}>
                        <Welcome greeting_message_title={this.state.greeting_message_title}
                                 greeting_message_content={this.state.greeting_message_content}/>
                    </View>
                );
            } else {
                return (
                    // <View style={styles.containerMain}>
                        <WelcomeAndImage greeting_message_title={this.state.greeting_message_title}
                                         greeting_message_content={this.state.greeting_message_content}
                                         welcomeMsgBackgroundImgUrl={this.state.welcome_msg_background_img}/>
                    // </View>

            );
            }
        }
    }

}

const styles = StyleSheet.create({

    containerMain: {
        flex: 1,
        backgroundColor: '#3272db',
        // textAlign: "center",
        // textAlignVertical: "center",
        // justifyContent: 'center',
        // alignItems: 'center'
        // position: 'absolute', top: 0, bottom: 0, left: 0, right: 0
    },
    activityIndicator: {
        flex: 1,
        alignSelf: 'center',
        color: "white",
        fontWeight: 'bold'
    },
    activityIndicatorContents: {
        color: "white",
        fontWeight: 'bold',
        flex: 1,
    }
});

