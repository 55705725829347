import * as React from 'react';
import {AppRegistry, StyleSheet, Image, TouchableHighlight, ActivityIndicator} from 'react-native';

import {Text, View} from '../components/Themed';
import PdfView5 from "../components/Pdf/PdfView5";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import {mainApiPassword, mainApiUsername, registerDeviceUrl} from "../apiConfig";

const styles1 = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center'
    }
})

const url = 'https://your-url.com/video.mp4'

export default class TabGuestGuideScreen extends React.Component {
    state = {
        isReady: false,
        source: 'none'
    }

    async componentDidMount() {
        // const device_id = await AsyncStorage.getItem("device_id");
        // const device_name = await AsyncStorage.getItem("device_name");
        const guest_guide_url = await AsyncStorage.getItem("guest_guide_url");
        // console.log("guest_guide_url: ",guest_guide_url);
        await this.setState({isReady:true, source:guest_guide_url})
        // const res = await axios.post(registerDeviceUrl, {"deviceUniqueId": device_id, "deviceName":device_name},

    }

    render() {
        // console.log(this.state.source);
        if (!this.state.isReady) {
            return (
                <View style={styles.activityIndicator}>
                    <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady}
                                       hidesWhenStopped={true} size="large" color="orange"/>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <PdfView5 source={this.state.source}/>
                </View>
            );
        }
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // flexDirection: "row",
        // alignItems: 'center',
        // justifyContent: 'center',
        // backgroundColor: '#8f153e'
        // backgroundColor: '#78828d'
        backgroundColor: '#000000'
    },
    title: {
        fontSize: 20,
        flex: 3,
        fontWeight: 'bold',
    },
    separator: {
        marginHorizontal: 30,
        width: 1,
        flex: 1,
        height: '80%',
    },
    videoImageLink: {
        width: 400,
        height: 400,
        flex: 1
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    activityIndicator: {
        flex: 1,
        // alignSelf: 'center',
        color: "white",
        fontWeight: 'bold',
        backgroundColor: '#fac97a',
    },
    activityIndicatorContents: {
        color: "white",
        fontWeight: 'bold',
        flex: 1,
    }
});


