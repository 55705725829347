import * as React from 'react';
import * as SplashScreen from "expo-splash-screen";
import * as Device from 'expo-device';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Platform, StyleSheet} from "react-native";
import 'react-native-get-random-values';
// import platform from 'platform';
var platform = require('platform');

export default function getDeviceInfoAndNetworkStatus() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);
    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        async function logDeviceInfo() {
            //DEBUG DEV LOGS
            console.log("##########  PLATFORM INFORMATION  ################", Platform.OS)
            console.log("Platform.OS ", Platform.OS)
            console.log("-----# WEB BROWSER #-----")
            console.log("Get OS name: ", Device.osName)
            console.log("Get OS Version: ", Device.osVersion)
            console.log("Get osBuildId: ", Device.osBuildId)
            console.log("Get osInternalBuildId: ", Device.osInternalBuildId)
            console.log("Get osBuildFingerprint: ", Device.osBuildFingerprint)
            console.log("====================")
            console.log("deviceId: ", platform.deviceId)
            console.log("deviceName: ", platform.deviceName)
            console.log("device_identifier: ", platform.device_identifier)
            console.log("version: ", platform.version)
            console.log("layout: ", platform.layout)
            console.log("manufacturer: ", platform.manufacturer)
            console.log("os: ", platform.os)
            console.log("description: ", platform.description)
            console.log("====================")
        }
        async function getExpoDeviceInfo() {

            var deviceInfo = {
                deviceId: "",
                deviceName: ""
            };
            var deviceSelected = true;
            if (Platform.OS === 'ios') {
                deviceInfo.deviceId = Device.osInternalBuildId + "_" + Device.deviceName;
                deviceInfo.deviceName = Device.deviceName + "_" + Device.osName
                // deviceInfo = {
                //     deviceId:Device.osInternalBuildId+"_"+Device.deviceName,
                //     deviceName:Device.deviceName+"_"+Device.osName
                // }
            } else if (Platform.OS === 'android') {
                deviceInfo.deviceId = Device.osInternalBuildId + "_" + Device.deviceName;
                deviceInfo.deviceName = Device.deviceName + "_" + Device.osName
                // deviceInfo = {
                //     deviceId:Device.osInternalBuildId+"_"+Device.deviceName,
                //     deviceName:Device.deviceName+"_"+Device.osName
                // }
            } else if (Platform.OS === 'web') {
                deviceInfo.deviceId = Device.osName + "_" + platform.layout + "_" + platform.os.family + "_" + platform.version + "_" + platform.os.architecture,
                    deviceInfo.deviceName = "" + platform.name + "_" + platform.os + "" + platform.version
                // deviceInfo = {
                //     // deviceId:""+Device.osName+"_"+Constants.osVersion,
                //     deviceId:Device.osName+"_"+platform.layout+"_"+platform.os.family+"_"+platform.version+"_"+platform.os.architecture,
                //     deviceName:""+platform.name+"_"+platform.os+""+platform.version
                //     // deviceId:"_"+Device.osName+"_"+Constants.osVersion,
                //     //     deviceName:"web_1"+Device.osName+"_"+Constants.osVersion
                // };
            } else {
                console.error("ERRORRRRRRRR DEVICE INFO   ");
                deviceSelected = false;
            }
            // if(deviceInfo)
            console.log("DETECTED DEVICE INFO");
            console.log("Saving device Info : ", deviceInfo);
            await AsyncStorage.setItem('device_id', <string>deviceInfo.deviceId);
            await AsyncStorage.setItem('device_name', <string>deviceInfo.deviceName);

        }
        async function loadDeviceStatus() {
            try {
                await getExpoDeviceInfo();
            } catch (e) {
                console.warn(e);
            } finally {
                setLoadingComplete(true);
                SplashScreen.hideAsync();
            }
        }
        // logDeviceInfo();
        loadDeviceStatus();
    }, []);
    return isLoadingComplete;
}
