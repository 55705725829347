import configData from './assets/data/config2.json';
var mainConfigData = configData.data;
// const apiUrl = mainConfigData.devApiUrl;
// const apiUrl = mainConfigData.devsApiUrl;
// const apiUrl = mainConfigData.testApiUrl;
var apiUrl = "https://app.primeyachting.hr/";
// var apiUrl = "http://localhost:8765/";

export var loginUrl = apiUrl + mainConfigData.loginUrl;
export var fetchBoatDataUrl = apiUrl + mainConfigData.boatInfoUrl;
export var checkDeviceRegisteredUrl =apiUrl + mainConfigData.checkIfDeviceRegisteredUrl;
export var registerDeviceUrl = apiUrl + mainConfigData.registerDeviceUrl;
export var mainApiUsername = mainConfigData.apiUsername;
export var mainApiPassword = mainConfigData.apiPassword;
export var sosInsertUrl = apiUrl + mainConfigData.sosInsertUrl;
export var getBoatGuestGuideUrl = apiUrl + mainConfigData.getGuestGuideUrl;
export var getBoatSpecsUrl = apiUrl + mainConfigData.getBoatSpecsUrl;
export var getVideoCheckInUrl = apiUrl + mainConfigData.getVideoCheckInUrl;

export var weatherInfoUrl = apiUrl + mainConfigData.weatherInfoUrl;
export var chatUrl = apiUrl + mainConfigData.chatUrl;
export var chatBookingUrl = apiUrl + mainConfigData.chatBookingUrl;
export var chatTransfersUrl = apiUrl + mainConfigData.chatTransfersUrl;
export var chatServiceUrl = apiUrl + mainConfigData.chatServiceUrl;
