import * as React from 'react';
import {View, StyleSheet, Text, SafeAreaView, ScrollView, StatusBar, ActivityIndicator} from 'react-native';
import ChatHolder1 from "../components/Chat/ChatHolder1";
import ChatHolder2 from "../components/Chat/ChatHolder2";
import ChatHolder3 from "../components/Chat/ChatHolder3";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {WebView} from "react-native-webview";
import {chatServiceUrl} from "../apiConfig";

export default class ServiceChatScreen extends React.Component {
    state = {
        isReady3: false,
        boat_name: 'unknown',
    }
    async componentDidMount() {
        var boat_name = await AsyncStorage.getItem("device_name");
        this.setState({isReady3: true, boat_name: boat_name});
    }
    render() {
        // if (!this.state.isReady3) {
        //     return (
        //         <View style={styles.activityIndicator}>
        //             <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady3} hidesWhenStopped={true} size="large" color="white"/>
        //         </View>
        //     )
        // } else {

            return (
                <View
                    style={{
                        flex: 1
                    }}>
                    <WebView style={styles.webview_default}
                             originWhitelist={['http://*', 'https://*']}
                             javaScriptEnabled={true}
                             domStorageEnabled={true}
                             startInLoadingState={false}
                             scalesPageToFit={false}
                             onNavigationStateChange={(event) => {
                                 // event.canGoForward = false;
                                 // this.ref.stopLoading();
                                 // console.log(event.goBack());
                                 // WebView.current.goBack();
                                 // this.webview.current.goBack()
                             }}
                             source={{
                                 uri: chatServiceUrl + this.state.boat_name+'/'
                             }}


                    />
                </View>
            );

        // }

    }
}
const styles = StyleSheet.create({
    webview_default: {
        marginTop: 0,
        // height: 200,
        // width: 320,
        flex: 1
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#fac97a',
        backgroundColor: '#a68128',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    containerMain: {
        flex: 1,
        // backgroundColor: '#fac97a',
        backgroundColor: '#a68128',
        textAlign: "center",
        textAlignVertical: "center",
        justifyContent: 'center',
        // alignItems: 'center'
        // position: 'absolute', top: 0, bottom: 0, left: 0, right: 0
    },
    activityIndicator: {
        flex: 1,
        // alignSelf: 'center',
        color: "black",
        fontWeight: 'bold',
        // backgroundColor: '#fd7c23',
        backgroundColor: '#a68128',
    },
    activityIndicatorContents: {
        color: "black",
        fontWeight: 'bold',
        flex: 1,
    }
});
