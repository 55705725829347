import React, {Component, useState} from 'react';
import {StyleSheet, View, ImageBackground, Text} from 'react-native';
import {RFPercentage, RFValue} from "react-native-responsive-fontsize";

export default function Welcome({
                                    greeting_message_title,
                                    greeting_message_content
                                }: { greeting_message_title: string, greeting_message_content: string }) {

    return (
        <ImageBackground source={{}} style={styles.imageBackground}>
            <Text style={styles.welcomeTitle}>
                {greeting_message_title}


            </Text>
            <View style={styles.separator}/>
            <Text style={styles.welcomeContent}>
                {greeting_message_content}
            </Text>
        </ImageBackground>
    );

    // return (
    //     <View style={{flex:1,padding:10,
    //         backgroundColor: '#3272db',
    //         textAlign: "center",
    //         // alignSelf:"center",
    //         // textAlignVertical: "center",
    //         justifyContent: 'center'
    //     }}>
    //         <Text style={styles.welcomeTitle}>
    //             {greeting_message_title}
    //
    //
    //         </Text>
    //         <View style={styles.separator}/>
    //         <Text style={styles.welcomeContent}>
    //             {greeting_message_content}
    //         </Text>
    //     </View>
    //         );

}
const styles = StyleSheet.create({
    welcomeTitle: {
        // fontSize: 40,
        // fontSize: RFValue(24, 580),
        fontSize: RFPercentage(4),
        fontWeight: 'bold',
        color: "white",
        fontFamily: "Yantramanav_300Light"
        // flex: 1,
    },
    welcomeContent: {
        // fontSize: 20,
        fontSize: RFPercentage(2),
        color: "white",
        fontWeight: 'bold',
        fontFamily: "Yantramanav_100Thin"
        // flex: 1,
    },
    separator: {
        marginVertical: 20,
        height: 1,
        // width: '80%',
        // backgroundColor:"#ef1a1a"
        backgroundColor: "white"
    },
    imageBackground: {
        // flex: 1,
        // flexDirection: "column",
        // alignItems: 'center',
        // resizeMode: 'cover',
        // justifyContent: 'center',
        // color: '#fff',
        padding: 20,
        // textAlign: 'center',

        flex: 1,
        backgroundColor: '#3272db',
        textAlign: "center",
        textAlignVertical: "center",
        justifyContent: 'center',


    }
});

