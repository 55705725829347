import * as React from 'react';
import {StyleSheet, Image, ActivityIndicator} from 'react-native';
// import PdfLink from '../components/Pdf/PdfLink';
import {Text, View} from '../components/Themed';
import PdfView5 from "../components/Pdf/PdfView5";
import {BaseScreen} from "./BaseScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import PDFView from 'react-native-view-pdf';


export default class TabVesselInfoScreen extends BaseScreen {
    state = {
        isReady: false,
        // isReady: true,
        source: 'none'
    }

    async componentDidMount() {
        const boat_specs_url = await AsyncStorage.getItem("boat_specs_url");
        // console.log("guest_guide_url: ",guest_guide_url);
        await this.setState({isReady:true, source:boat_specs_url})
        // const res = await axios.post(registerDeviceUrl, {"deviceUniqueId": device_id, "deviceName":device_name},

    }

    render() {
        // console.log(this.state.source);
        if (!this.state.isReady) {
            return (
                <View style={styles.activityIndicator}>
                    <ActivityIndicator style={styles.activityIndicatorContents} animating={!this.state.isReady}
                                       hidesWhenStopped={true} size="large" color="orange"/>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <PdfView5 source={this.state.source}/>
                </View>
            );
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor:'#157d8f'
        backgroundColor:'#000000'
        // flexDirection: "row",
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    activityIndicator: {
        flex: 1,
        // alignSelf: 'center',
        color: "white",
        fontWeight: 'bold',
        // backgroundColor: '#fac97a',
        backgroundColor: '#000000',
    },
    activityIndicatorContents: {
        color: "white",
        fontWeight: 'bold',
        flex: 1,
    },
    title: {
        fontSize: 20,
        flex: 3,
        fontWeight: 'bold',
    },
    separator: {
        marginHorizontal: 30,
        width: 1,
        flex: 1,
        height: '80%',
    },
    videoImageLink: {
        width: 400,
        height: 400,
        flex: 1
    },
});
